@tailwind base;
@tailwind components;
@tailwind utilities;

::marker {
  color: red;
}
* {
  font-family: Arial, Helvetica, sans-serif;
}

.css-1p5q5e5-MuiStack-root > :not(style) + :not(style) {
  margin-top: 6px;
}

.css-1t8l2tu-MuiInputBase-input-MuiOutlinedInput-inputP {
  padding: 14.5px 14px;
}

.css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input {
  padding: 0px 0px 0px 4px;
}
#demo-simple-select1 {
  height: 38px;
  display: flex;
  justify-content: start;
  align-items: center;
  /* overflow-x: 'hidden'; 
  white-space: nowrap;
  text-overflow: ellipsis; */
  padding-left: 15px;
  color: rgba(0, 0, 0, 0.6);
}
#description1 {
  height: 0.4375em;
}
#price2 {
  height: 0.4375em;
}

.css-1t1j96h-MuiPaper-root-MuiDialog-paper {
  max-width: 900px !important;
}
.fc-day-past {
  background-color: gray;
  opacity: 0.1;
}
.p-4 {
  padding: 0rem !important;
}
.custom-style {
  background-color: #0000ff;
  opacity: 0.1;
}
.fc-timeline-slot-label {
  opacity: 0.8;
}
.css-qiwgdb {
  padding: 9px 32px !important;
}
.my-calendar {
  /* border: 1px solid black; */
  /* height: 40px; */
  /* margin: auto; */

  border-radius: 4px;
  box-shadow: rgba(67, 71, 85, 0.27) 0px 0px 0.25em,
    rgba(90, 125, 188, 0.05) 0px 0.25em 1em;
}
.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}
/* width */
::-webkit-scrollbar {
  width: 6px;
  height: 8px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #888;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #949494;
}
