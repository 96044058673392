.loader-container {
  display: inline-flex;
  justify-content: center;
  align-items: center;
}

.loader-dot {
  width: 16px;
  height: 16px;
  background-color: #3498db; /* Change to your desired color */
  border-radius: 50%;
  margin: 0 6px;
  animation: loader-bounce 1.4s infinite ease-in-out;
}

.loader-dot1 {
  animation-delay: -0.32s;
}

.loader-dot2 {
  animation-delay: -0.16s;
}

.blur-overlay {
  position: relative;
  z-index: 999999;
}

.blur-overlay::before {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  backdrop-filter: blur(8px); /* Adjust the blur value as needed */
  background: rgba(
    255,
    255,
    255,
    0.5
  ); /* Adjust the background color and opacity as needed */
}

@keyframes loader-bounce {
  0%,
  80%,
  100% {
    transform: scale(0);
  }
  40% {
    transform: scale(1);
  }
}
