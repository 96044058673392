.hotelstatus-cont {
  position: sticky;
  /* top: 0; */
  background-color: rgb(41, 162, 228);
  z-index: 1;
  padding: 5px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  border-radius: 4px 4px 0 0;
}

.hotelstatus-main-cont {
  height: calc(100% - 100px);
  overflow-y: auto;
  border: 1px solid #ccc;
  padding: 5px;
}
.hotel-status-mapdata {
  border-bottom: 1px solid #ccc;
  border-left: 1px solid #ccc;
  border-right: 1px solid #ccc;
  padding: 4px;
}
.hotel-status-mapdata p {
  margin: 0;
  font-size: 14px;
}

/* revenueStatus css --------------  */
.revenue-main-cont {
  border: 1px solid #ccc;
  height: calc(100% - 65px);
  border-radius: 4px;

}
.revenue-main-cont h3 {
  background-color: rgb(41, 162, 228);
  padding: 5px;
  margin-bottom: 30px;
}
.revenue-second-cont {
  margin: auto;
  padding: 0 5px;
}
.revenue-second-cont h1 {
  font-size: 40px;
  margin: 10px 0;
  border-bottom: 1px solid #ccc;
}
.revenue-second-cont p {
  display: flex;
  justify-content: center;
}


/* occupancy css -------------------  */
.occupancy-heading{
  background-color: rgb(41, 162, 228);
  padding: 5px;
}


/* source css ----------------------  */
/* Add these CSS styles to your stylesheet */

/* Container for buttons */
.source-main-btn-cont {
  display: flex;
  justify-content: space-around;
  margin-bottom: -10px;
}

/* Individual button style */
.source-main-btn-cont button {
  /* background-color: #f0f0f0; */
  border: none;
  padding: 4px 20px;
  font-size: 16px;
  cursor: pointer;
  outline: none;
  transition: background-color 0.3s ease;
}

/* Active button style with underline */
.source-main-btn-cont button.active {
  /* background-color: #ddd; */
  color: blue;
  border-bottom: 2px solid blue; /* Change the color for the underline */
}
